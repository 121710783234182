import React from "react";
import {Config} from "../types";
import { Auth0Provider } from "@auth0/auth0-react";
import Landing from "./Landing";

export function EnrollEmailRoute({domain, audience, clientId, emailPublicKey}: Config) {
  // TODO: hard to test this sdk
  // https://community.auth0.com/t/unit-testing-with-auth0/45814
  // unit test story: https://arlive.atlassian.net/browse/IDAPI-1588
  // risk of no access restrictions: https://arlive.atlassian.net/browse/IDAPI-906?focusedCommentId=918940
  return <Auth0Provider
    domain={domain}
    clientId={clientId}
    audience={audience}
    scope="read:authenticators remove:authenticators enroll openid profile email"
    redirectUri={window.location.origin + '/email-enroll'}
  >
    <Landing publicKey={emailPublicKey} authConfig={{
      domain: domain,
      clientId: clientId,
      audience: audience
    }} />
  </Auth0Provider>
}
